import {
  useState,
  ChangeEvent,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
} from 'react'
import TextField from '@mui/material/TextField'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import styles from './styles.module.css'
import { fetchData } from '../../utils/fetch'
import { getGeocode } from './api'
import { GEOCODE_PLACE_TYPES } from '../../constants/google-maps-address'
import { UnvalidatedAddress } from '../../../schedule/components/schedule-modal/components/unvalidated-address/UnvalidatedAddress'
import { OrderContext } from '../../../global/context/order-context/OrderContext'
import removeDoubledCommas from '../../utils/remove-doubled-commas'
import calculateFullAddress from '../../utils/installation-point/calculate-full-address'
import { ILocation } from '../../../../../app/entities/Location'

interface IGoogleAddressSearchBarProps {
  isZipCodeServiced: boolean | undefined
  setChosenAddress: Dispatch<SetStateAction<google.maps.GeocoderResult | null>>
  activeLocation: ILocation | undefined
  setIsZipCodeServiced: Dispatch<SetStateAction<boolean | undefined>>
  setUseUnvalidatedAddress: Dispatch<SetStateAction<boolean>>
}

export default function GoogleAddressSearchBar({
  isZipCodeServiced,
  setIsZipCodeServiced,
  activeLocation,
  setChosenAddress,
  setUseUnvalidatedAddress,
}: IGoogleAddressSearchBarProps) {
  const { orderData } = useContext(OrderContext)
  const { installationPoint } = orderData

  const [addressInput, setAddressInput] = useState<string>(
    calculateFullAddress(installationPoint ?? {}),
  )
  const [hasChosenAddress, setHasChosenAddress] = useState(false)
  const [unvalidatedAddress, setUnvalidatedAddress] = useState<string>('')
  const [isOpenUnvalidatedAddressModal, setIsOpenUnvalidatedAddressModal] =
    useState(false)
  const isLocationSelected = activeLocation && !Array.isArray(activeLocation)
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      options: {
        input: removeDoubledCommas(installationPoint?.fullAddress),
        types: [
          GEOCODE_PLACE_TYPES.street_address,
          GEOCODE_PLACE_TYPES.street_number,
          GEOCODE_PLACE_TYPES.premise,
        ], //returns only valid addresses using table 2 parameters from https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
        componentRestrictions: {
          country: 'us',
        },
      },
    })

  async function handlePredictionClick(
    address: google.maps.places.AutocompletePrediction,
  ) {
    setHasChosenAddress(true)
    setAddressInput(address.description)
    getPlacePredictions({
      input: '',
    })

    const { results } = (await fetchData(
      getGeocode(address.description),
    )) as google.maps.GeocoderResponse

    if (!(results && results.length > 0))
      return alert('No address found, try again')

    const chosenAddress = results[0]

    if (setChosenAddress) {
      setChosenAddress(chosenAddress)
    }

    const zipCode = chosenAddress.address_components.find((component) =>
      component.types.includes(GEOCODE_PLACE_TYPES.postal_code),
    )

    //in some instances Google might recommend an address without a valid geoCode that we cannot schedule
    //we will send the CS Rep through the unvalidated address flow to resolve this issue
    //these are called address approximates and are more common with newer developments
    //they are typified by an undefined zipCode
    if (zipCode === undefined) {
      setHasChosenAddress(false)
      setChosenAddress(null)
      unvalidatedAddressHandler(addressInput)
    }

    setUseUnvalidatedAddress(false)
    const isServiced = await fetchData<any>({
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode?.long_name}/availability?locationObjectId=${activeLocation?.objectId}`,
    })
    setIsZipCodeServiced(isServiced.serviced)
  }

  // If we already have an address on the order, we should use
  // it as the initial value
  useEffect(() => {
    if (orderData.installationPoint) {
      const address = calculateFullAddress(installationPoint)
      setAddressInput(address)
      addressHandler({
        target: { value: address },
      } as ChangeEvent<HTMLInputElement>)
    }
  }, [])
  async function addressHandler(event: ChangeEvent<HTMLInputElement>) {
    if (setChosenAddress) {
      setChosenAddress(null)
    }
    setHasChosenAddress(false)
    setIsZipCodeServiced(undefined)
    const addressValue = event.target.value

    setAddressInput(addressValue)

    getPlacePredictions({
      input: addressValue,
    })

    const locationBias = isLocationSelected
      ? {
          center: {
            lat: activeLocation?.coordinates.y,
            lng: activeLocation?.coordinates.x,
          },
          radius: 50000,
        }
      : undefined

    if (isLocationSelected) {
      getPlacePredictions({
        input: addressValue,
        locationBias,
      })
    }
  }

  // Reset the address info when the location changes
  useEffect(() => {
    if (setChosenAddress) {
      setChosenAddress(null)
    }
    setAddressInput('')
    setHasChosenAddress(false)
    setIsZipCodeServiced(false)
  }, [activeLocation])

  function unvalidatedAddressHandler(addressInput: string) {
    setHasChosenAddress(true)
    setUnvalidatedAddress(addressInput)
    setIsOpenUnvalidatedAddressModal(true)
  }

  const hasPlacePredictionsAndNoAddressChosen =
    placePredictions && placePredictions.length > 0 && !hasChosenAddress

  return (
    <div>
      {hasChosenAddress && unvalidatedAddress && (
        <UnvalidatedAddress
          isOpen={isOpenUnvalidatedAddressModal}
          setIsThisOpen={setIsOpenUnvalidatedAddressModal}
          typedAddress={unvalidatedAddress}
          isZipCodeServiced={isZipCodeServiced}
          setIsZipCodeServiced={setIsZipCodeServiced}
          setUseUnvalidatedAddress={setUseUnvalidatedAddress}
        />
      )}
      <div className={styles.addressContainer}>
        <div>
          <TextField
            name='address'
            label={'Address'}
            onChange={addressHandler}
            value={addressInput}
            autoComplete='off'
            style={{ width: '400px' }}
          />
          <div
            className={styles.addressResultsHolder}
            style={{ display: placePredictions?.length > 0 ? 'block' : 'none' }}
          >
            {isPlacePredictionsLoading ? (
              <div className={styles.addressResult}>
                <i>Loading...</i>
              </div>
            ) : (
              hasPlacePredictionsAndNoAddressChosen &&
              placePredictions.map(
                (
                  prediction: google.maps.places.AutocompletePrediction,
                  idx: number,
                ) => {
                  return (
                    <div
                      key={idx}
                      className={styles.addressResult}
                      onClick={() => handlePredictionClick(prediction)}
                    >
                      <div className={styles.addressResultIcon} />
                      <div className={styles.addressResultDescription}>
                        {prediction.description}
                      </div>
                    </div>
                  )
                },
              )
            )}
          </div>
          {unvalidatedAddressHandler && !hasChosenAddress && (
            <div
              className={styles.addressResultsHolder}
              style={{
                display:
                  placePredictions?.length === 0 && addressInput.length > 5
                    ? 'block'
                    : 'none',
              }}
            >
              <div
                className={styles.addressResult}
                onClick={() => unvalidatedAddressHandler(addressInput)}
              >
                <div className={styles.addressResultNoResult}>
                  {`Don't see the address you are looking for? Click here.`}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
