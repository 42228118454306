export const ORDER_SOURCES = {
  bopis: 'BOPIS',
  inStore: 'In Store',
  phone: 'Phone',
  tirerack: 'Tire Rack',
  aaa: 'AAA',
}
export const TIRE_SOURCES = {
  tirerack: 'Tire Rack',
  discounttire: 'Discount Tire',
  customer: 'Customer Has Tires',
}
export const TIRE_SOURCES_ARRAY = Object.values(TIRE_SOURCES)
export const ORDER_SOURCES_ARRAY = Object.values(ORDER_SOURCES)
