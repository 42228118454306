import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from '../styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { IRolePartial } from '../../../../../app/entities/Role'
import { listPermissions, getRoleByName } from '../../api'
import { IPermission } from '../../../../../app/entities/Permission'
import { TextField } from '@mui/material'
import RoleDropDown, { ROLE_DROPDOWN_ID } from '../../common/RoleDropDown'
import generatePermissionOptions, {
  PermissionsDetails,
} from './generate-permission-options'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
interface IRoleCreateProps {
  roleData: IRolePartial
  setRoleData: Function
}

const NAME_ID = 'name'

function RoleCreate({ roleData, setRoleData }: IRoleCreateProps) {
  const { description, name, permissions: assignedPermissions } = roleData

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [templateRoleName, setTemplateRoleName] = useState<string>(name || '')
  const [allPermissions, setAllPermissions] = useState<IPermission[]>([])
  const [permissionDetails, setPermissionDetails] =
    useState<PermissionsDetails>({})
  const [permissionOptions, setPermissionOptions] = useState<JSX.Element>(<></>)

  async function fetchPermissionsInformation() {
    const res = await fetchData<{ count: number; items: IPermission[] }>(
      listPermissions(),
    )
    const { count, items: permissions } = res

    const groupedPermissions = permissions.reduce((acc, permission) => {
      const { category, description, name } = permission

      if (!acc[category]) {
        acc[category] = {}
      }

      if (!acc[category][name]) {
        acc[category][name] = { allowedActions: [], description }
      }

      const entry = acc[category][name]
      entry.allowedActions.push(permission.action)

      return acc
    }, {} as PermissionsDetails)

    setAllPermissions(permissions)
    setPermissionDetails(groupedPermissions)
  }

  useEffect(() => {
    fetchPermissionsInformation()
  }, [])

  useEffect(() => {
    generatePermissionOptions(
      allPermissions,
      assignedPermissions ?? [],
      permissionDetails,
      roleData,
      setRoleData,
      setPermissionOptions,
    )
  }, [roleData, permissionDetails])

  function handleChangeForm(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    let updatedData = { ...roleData }

    if (name === ROLE_DROPDOWN_ID) {
      setTemplateRoleName(value)
      return
    }

    updatedData = { ...updatedData, [name]: value }

    setRoleData(updatedData)
  }

  async function setPermissionsToTemplateRole() {
    if (!templateRoleName) {
      return
    }

    const templateRole = await fetchData<IRolePartial>(
      getRoleByName(templateRoleName),
    )

    if (templateRole) {
      setRoleData({
        ...roleData,
        permissions: templateRole.permissions,
      })
    } else {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Role not found!',
        severity: 'error',
      })
    }
  }

  useEffect(() => {
    setPermissionsToTemplateRole()
  }, [templateRoleName])

  return (
    <div className={styles.formContainer}>
      <div className={styles.formTextFields}>
        <TextField
          value={name}
          onChange={handleChangeForm}
          label='Name'
          name={NAME_ID}
          fullWidth
        />

        <RoleDropDown
          initialValue={templateRoleName}
          onChange={handleChangeForm}
          label='Template Role'
        />
      </div>
      {permissionOptions}
    </div>
  )
}

export default RoleCreate
