import TextField from '@mui/material/TextField'
import { useContext } from 'react'
import { IOrder } from '../../../../../../../app/entities/Order'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../global/utils/fetch'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import styles from './styles.module.css'
import { overwriteOrderNote } from '../../../api'

interface IWorkOrderNotesProps {
  isReadOnly: boolean
}

export default function WorkOrderNotes({ isReadOnly }: IWorkOrderNotesProps) {
  const { orderData, setOrderData } =
    useContext(OrderContext)
  const orderObjectId = orderData.objectId

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  async function handleEditNote() {
    try {
      await fetchData<IOrder>(
        overwriteOrderNote(orderObjectId ?? '', orderData.note ?? ''),
      )
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        severity: 'success',
        message: 'Note has successfully saved!',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        severity: 'error',
        message: 'Error saving note!',
      })
    }
  }
  return (
    <>
      <div>
        <div className={styles.heading}>Work Order Note</div>
        <div className={styles.container}>
          <TextField
            sx={{ width: '100%', height: '100%' }}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={10}
            label='Edit Note'
            value={orderData.note}
            disabled={isReadOnly}
            onChange={(e) => {
              setOrderData({ note: e.target.value })
            }}
          />
          <button
            className={styles.saveButton}
            onClick={handleEditNote}
            disabled={isReadOnly}
          >
            Save Note
          </button>
        </div>
      </div>
    </>
  )
}
